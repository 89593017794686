#da-slider{
    /*background: transparent url(../../images/itServices.jpg) repeat 0% 0%;*/
    background: transparent url(../images/delfingen-icon-grey.png) repeat;
    background-size:  350px 350px;
    height: 350px;
}

.da-slide h2 .slide-text {
    background: url(../images/bg-heading-dark-blue.png) repeat;
    font-size: 36px;
    font-style:normal;
    padding:0;
    text-transform:uppercase;
}

.da-slide h2 {
    top:50px;
}

.da-slide p {
    top:250px;
}

.da-slide .da-img {
    top:30px !important;
}

.profile-blog  i {
    color: #4765a0;
}

.profile-blog i:hover {
    color: #324c80;
}

.profile-blog ul li i {
    color: #4765a0;
}

.profile-blog ul li i:hover {
    color: #324c80;
}

.profile .name-location span i {
    color: #4765a0;
}


.profile .name-location span i:hover {
    color: #324c80;
}

i.style-switcher-btn {
    top:2px;
    padding: 4px 5px;
}

.style-switcher {
    top:2px;
    display:block;
}

/* Add here all your css styles (customizations) */
/*.da-slider{
	background: transparent  url() no-repeat 100% 100%;
}*/

.topbar ul.loginbar > li > a.header-user {
    text-transform: none;
}

span.label-x2 {
    font-size:18px;
}

.breadcrumb li.active a {
    color: #4765a0;
}

.my-repeat-animation.ng-enter,
.my-repeat-animation.ng-leave,
.my-repeat-animation.ng-move {
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
    position:relative;
}

.my-repeat-animation.ng-enter {
    left:-10px;
    opacity:0;
}
.my-repeat-animation.ng-enter.ng-enter-active {
    left:0;
    opacity:1;
}

.my-repeat-animation.ng-leave {
    left:0;
    opacity:1;
}
.my-repeat-animation.ng-leave.ng-leave-active {
    left:-10px;
    opacity:0;
}

.my-repeat-animation.ng-move {
    opacity:0.5;
}
.my-repeat-animation.ng-move.ng-move-active {
    opacity:1;
}

.postit {
    height: 180px;
}

.yellow-postit {
    background-color: #fefdca;
}

.directory-flag {
    width:20px;
    height:17px;
    background-size:100% 100%;
    background-repeat:no-repeat;
}

.carousel-slide {
    position:relative;
    top:-33px;
    width:812px;
}

.admin-slide img {
    left: -120px !important;	
}

.da-slider-fb .da-slide .da-img {
    left: 45% !important;
}

.btn-admin-carousel{
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 80px;
}

.moveHandle {
    display: none;
}
a:hover .moveHandle, a:focus .moveHandle {
    display: inline-block;
    border: 2px lightgray dotted;
    width: 6px;
    height: 85%;
    position: absolute;
    top: 7.5%;
    left: 2px;
    padding: 0;
    margin: 0;
    border-top: 0px;
    border-bottom: 0px;
}
.placeholder {
    margin: 0 !important;
    margin-top: 1px !important;
    display: block;
}
span[filters="SlideFilters"] {
    max-width: 100% !important;
}
.backup-bg {
    height:100%;
    background-image: url('../images/delfingen_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
}
.backup-welcome-text {
    height: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
